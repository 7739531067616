import { useContext } from 'react';
import { AuthDispatchContext } from '../Contexts/auth';

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}

export default useAuthDispatch;
