import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';

function Breadcrumbs() {
  const location = useLocation();
  const { pathname } = location;

  const pathnames = pathname.split('/').filter((path) => path);

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{ flexGrow: 1, color: '#ffffff', marginLeft: 2 }}
      separator={<Icon path={mdiChevronRight} size={1} />}
    >
      {pathnames.length > 0 ? (
        <MuiLink component={Link} to="/" sx={{ color: '#ffffff' }}>
          Home
        </MuiLink>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name} sx={{ textTransform: 'capitalize' }}>
            {name?.replaceAll('-', ' ')}
          </Typography>
        ) : (
          <MuiLink
            key={name}
            component={Link}
            to={routeTo}
            sx={{ textTransform: 'capitalize', color: '#ffffff' }}
          >
            {name?.replaceAll('-', ' ')}
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
