import { Outlet } from 'react-router-dom';
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Header/Header';
// import Footer from '../Footer/Footer';

function Dashboard(): JSX.Element {
  return (
    <main data-testid="Dashboard">
      <Sidebar>
        {/* <Header /> */}
        <Outlet />
        {/* <Footer /> */}
      </Sidebar>
    </main>
  );
}

export default Dashboard;
