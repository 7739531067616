import {
  mdiHeartPulse,
  mdiServer,
  mdiScaleBalance,
  mdiSourceBranch,
  mdiCloud,
  mdiFlag,
  mdiFlask,
  mdiHistory,
  mdiNote,
  mdiCheckCircle,
  mdiAccount,
  mdiMenu,
  mdiChevronLeftCircle,
  mdiChevronRightCircle,
  mdiHeart,
  mdiHeartBroken,
  mdiAlert,
  mdiThermometer,
  mdiFolderOpen,
  mdiCellphone,
  mdiApps,
} from '@mdi/js';

const icons = {
  heartbeat: mdiHeartPulse,
  server: mdiServer,
  balanceScale: mdiScaleBalance,
  codeBranch: mdiSourceBranch,
  cloud: mdiCloud,
  flag: mdiFlag,
  flask: mdiFlask,
  history: mdiHistory,
  stickyNote: mdiNote,
  checkCircle: mdiCheckCircle,
  user: mdiAccount,
  bars: mdiMenu,
  chevronCircleLeft: mdiChevronLeftCircle,
  chevronCircleRight: mdiChevronRightCircle,
  heart: mdiHeart,
  heartBroken: mdiHeartBroken,
  exclamationTriangle: mdiAlert,
  thermometerThreeQuarters: mdiThermometer,
  folderOpen: mdiFolderOpen,
  mobileAlt: mdiCellphone,
  boxes: mdiApps,
};

export default icons;
