import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from './components/Dashboard/Dashboard';
import Login from './pages/Login/Login';

import routes from './constants/routes';
import RequireAuth from './utils/requireAuth';
import './App.scss';

function App(): JSX.Element {
  return (
    <div data-testid="app-div" className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Dashboard />}>
            {routes.map((route) => (
              <Route key={route.path} element={<RequireAuth />}>
                <Route path={route?.path} element={<route.component />} />
              </Route>
            ))}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
