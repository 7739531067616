const getApiUrl = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return 'http://localhost:3000/local';
    case 'development':
      return 'https://b3z0fqu81c.execute-api.us-east-1.amazonaws.com/dev';
    default:
      return 'https://api-devdash.awsdrinp.buymyri.de';
  }
};

export default getApiUrl;
