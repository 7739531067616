import Status from '../common/status';

export interface IInitialState {
  user: string | null;
  token: string;
  status: string;
  errorMessage?: string;
}

const initialState: IInitialState = {
  user: null,
  token: '',
  status: Status.Idle,
};

export default initialState;
