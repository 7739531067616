import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import Typography from '@mui/material/Typography';
import { TCardItem } from '../../pages/NewRelicAlerts/interfaces';
import fmt from '../../utils/dateTimeFormat';

function TimelineCard({ description, timestamp }: TCardItem) {
  const title = description?.split(' ', 1)[0];
  const content = description?.split(' ').splice(1).join(' ') || '';
  const isViolated = description?.includes('violated');

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0.1 }}>
        <Typography>{timestamp && fmt.format(new Date(timestamp))}</Typography>
      </TimelineOppositeContent>

      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color={!isViolated ? 'success' : 'error'} variant="filled">
          <Icon path={!isViolated ? mdiCheckCircle : mdiCloseCircle} size={1} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Card>
          <CardHeader title={title} />
          <CardContent>
            <Typography>{content.charAt(0).toUpperCase() + content.slice(1)}</Typography>
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}

export default TimelineCard;
