import axios from 'axios';
import getApiUrl from '../config';
import IuserToken from '../interfaces/userToken';

let userToken: IuserToken = {
  name: '',
  value: '',
};

const setUserToken = (newToken: IuserToken) => {
  userToken = newToken;
};

const getAccessToken = () => userToken.value;

const validateToken = async (newToken: IuserToken) => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      await axios.post(`${getApiUrl()}/oauth/login/validate`, newToken.value);
    }
    axios.defaults.headers.common['x-access-token'] = newToken.value;
    setUserToken(newToken);
  } catch (e) {
    // console.log('handle error');
  }
};

export { validateToken, setUserToken, getAccessToken };
