import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Icon from '@mdi/react';
import { mdiFilterVariant } from '@mdi/js';

interface IFilters {
  filters: Array<string>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Filters({ filters, handleChange }: IFilters) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper sx={{ padding: 1 }}>
      <Button
        onClick={() => setExpanded(!expanded)}
        variant="contained"
        startIcon={<Icon path={mdiFilterVariant} size={1} />}
        data-testid="expand-button"
      >
        Advanced Filters
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <br />
        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={0}>
          <FormControlLabel
            label="Development"
            control={
              <Switch
                value="dev"
                checked={filters.includes('dev')}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Development' }}
              />
            }
          />
          <FormControlLabel
            label="Non-Prod"
            control={
              <Switch
                value="np"
                checked={filters.includes('np')}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Non-Prod' }}
              />
            }
          />
          <FormControlLabel
            label="Pre-Prod"
            control={
              <Switch
                value="pp"
                checked={filters.includes('pp')}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Pre-Prod' }}
              />
            }
          />
          <FormControlLabel
            label="Production"
            control={
              <Switch
                value="prod"
                checked={filters.includes('prod')}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Production' }}
              />
            }
          />
        </Stack>
      </Collapse>
    </Paper>
  );
}
