import './PagesHeader.scss';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import React from 'react';

interface IPagesHeaderProps {
  filter?: IOptions;
  toogleButton?: IOptions;
}

const defaultProps: IPagesHeaderProps = {
  filter: undefined,
  toogleButton: undefined,
};

interface IOptions {
  function: React.ChangeEventHandler;
  value?: string;
  text?: string;
}

function PagesHeader({ filter, toogleButton }: IPagesHeaderProps) {
  return (
    <header className="page-header">
      {filter && (
        <TextField
          className="filter-Healthy"
          id="outlined-basic"
          label="Type to filter.."
          variant="outlined"
          size="small"
          value={filter.value}
          onChange={filter.function}
          inputProps={{ 'aria-label': 'filter' }}
        />
      )}
      {toogleButton && (
        <FormControlLabel
          control={
            <Switch
              onChange={toogleButton.function}
              inputProps={{ 'aria-label': 'healthy-check' }}
            />
          }
          label={toogleButton.text}
        />
      )}
    </header>
  );
}

PagesHeader.defaultProps = defaultProps;

export default PagesHeader;
