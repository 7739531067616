import icons from './icons';
import paths from './paths';

const categories = [
  {
    categoryName: 'HEALTH',
    menuItems: [
      {
        name: 'Health Check',
        icon: icons.heartbeat,
        path: paths.healthCheck,
      },
      {
        name: 'New Relic Alerts',
        icon: icons.server,
        path: paths.newRelicAlerts,
      },
      {
        name: 'Sonar Quality',
        icon: icons.balanceScale,
        path: paths.sonarQuality,
      },
    ],
  },
  {
    categoryName: 'VERSIONS',
    menuItems: [
      {
        name: 'App Versions',
        icon: icons.codeBranch,
        path: paths.appVersions,
      },
      {
        name: 'Deployed Versions',
        icon: icons.cloud,
        path: paths.deployedVersions,
      },
    ],
  },
  {
    categoryName: 'EXPERIMENTATION',
    menuItems: [
      {
        name: 'Feature Flags',
        icon: icons.flag,
        path: paths.featureFlags,
      },
      {
        name: 'Experiments',
        icon: icons.flask,
        path: paths.experiments,
      },
      {
        name: 'Changes History',
        icon: icons.history,
        path: paths.changesHistory,
      },
    ],
  },
  {
    categoryName: 'RELEASE',
    menuItems: [
      {
        name: 'Recent Commits',
        icon: icons.stickyNote,
        path: paths.recentCommits,
      },
      {
        name: 'Release State',
        icon: icons.checkCircle,
        path: paths.releaseState,
      },
      {
        name: 'Release Scope',
        icon: icons.user,
        path: paths.releaseScope,
      },
    ],
  },
];

export default categories;
