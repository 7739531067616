import { useQuery } from 'react-query';
import client from '../../utils/client';

const getHealthChecks = () =>
  client({
    method: 'get',
    url: '/health',
  });

const useFetchHealthChecks = () => {
  const { data, isLoading } = useQuery('HealthCheck', getHealthChecks);
  return { data, isLoading };
};

export default useFetchHealthChecks;
