import axios, { AxiosRequestConfig } from 'axios';
import getApiUrl from '../config';

const client = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Content-type': 'application/json',
  },
});

client.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  // eslint-disable-next-line no-param-reassign
  config.headers['x-access-token'] = localStorage.getItem('token') || '';
  return config;
});

export default client;
