import React from 'react';
import './Card.scss';

interface ICardProps {
  data: string;
  healthy: boolean;
}

function Card({ data, healthy }: ICardProps): JSX.Element {
  return (
    <div className={`card-health-container bg-${healthy ? 'health' : 'unhealth'}`}>
      <p>{data}</p>
    </div>
  );
}

export default Card;
