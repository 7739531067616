import React, { useState } from 'react';
import Card from './components/Card';
import Loader from '../../components/Loader/Loader';
import PagesHeader from '../../components/PagesHeader/PagesHeader';
import useFetchHealthChecks from '../../services/api/useFetchHealthChecks';
import './HealthCheck.scss';

function HealthCheck(): JSX.Element {
  const { data, isLoading } = useFetchHealthChecks();
  const [hideHealth, setHideHealth] = useState(false);
  const [filter, setFilter] = useState('');
  console.log(data);

  const handleSwitchChange = () => {
    setHideHealth(!hideHealth);
  };

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(value);
  };

  const filterOptions = { function: handleChange, value: filter };

  const toogleOptions = { function: handleSwitchChange, text: 'Hide Healthy ' };

  if (isLoading) {
    return <Loader />;
  }

  const checks = Object.entries(data?.data);

  return (
    <>
      <PagesHeader filter={filterOptions} toogleButton={toogleOptions} />
      <div data-testid="health-container" className="health-container">
        {checks &&
          checks.reduce((acc: Array<JSX.Element>, [service, healthy]) => {
            const text = service.slice(0, -6).replace(/Health|Check|DRI/gi, '');
            if (hideHealth && healthy) {
              return acc;
            }
            if (text.toLocaleLowerCase().includes(filter)) {
              return [...acc, <Card healthy={Boolean(healthy)} key={text} data={text} />];
            }
            return acc;
          }, [])}
      </div>
    </>
  );
}

export default HealthCheck;
