import React, { createContext, useReducer /* useMemo */ } from 'react';
import initialState from './initialState';
import authReducer from './reducer';

export const AuthStateContext = createContext(initialState);

export const AuthDispatchContext = createContext(() => {});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // const stateValue = useMemo(() => state, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export default AuthProvider;
