import AppVersions from '../pages/AppVersions/AppVersions';
import ChangesHistory from '../pages/ChangesHistory/ChangesHistory';
import DeployedVersions from '../pages/DeployedVersions/DeployedVersions';
import Experiments from '../pages/Experiments/Experiments';
import FeatureFlags from '../pages/FeatureFlags/FeatureFlags';
import HealthCheck from '../pages/HealthCheck/HealthCheck';
import HomePage from '../pages/HomePage/HomePage';
import NewRelicAlerts from '../pages/NewRelicAlerts/NewRelicAlerts';
import RecentCommits from '../pages/RecentCommits/RecentCommits';
import ReleaseScope from '../pages/ReleaseScope/ReleaseScope';
import ReleaseState from '../pages/ReleaseState/ReleaseState';
import SonarQuality from '../pages/SonarQuality/SonarQuality';
import paths from './paths';

const routes = [
  {
    path: paths.home,
    component: HomePage,
  },
  {
    path: paths.healthCheck,
    component: HealthCheck,
  },
  {
    path: paths.newRelicAlerts,
    component: NewRelicAlerts,
  },
  {
    path: paths.sonarQuality,
    component: SonarQuality,
  },
  {
    path: paths.appVersions,
    component: AppVersions,
  },
  {
    path: paths.deployedVersions,
    component: DeployedVersions,
  },
  {
    path: paths.featureFlags,
    component: FeatureFlags,
  },
  {
    path: paths.experiments,
    component: Experiments,
  },
  {
    path: paths.changesHistory,
    component: ChangesHistory,
  },
  {
    path: paths.recentCommits,
    component: RecentCommits,
  },
  {
    path: paths.releaseState,
    component: ReleaseState,
  },
  {
    path: paths.releaseScope,
    component: ReleaseScope,
  },
];

export default routes;
