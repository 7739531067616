import { useQuery } from 'react-query';
import client from '../../utils/client';

const getNewRelicAlerts = () =>
  client({
    method: 'get',
    url: '/newrelic-events',
  });

const useFetchNewRelicAlerts = () => {
  const { isLoading, data } = useQuery('alerts', getNewRelicAlerts);
  return { data, isLoading };
};

export default useFetchNewRelicAlerts;
