import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader(): JSX.Element {
  return (
    <Box data-testid="Loader" sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
