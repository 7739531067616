import React from 'react';
import cards from '../../constants/cards';
import Card from './components/Card/Card';
import './HomePage.scss';

function HomePage(): JSX.Element {
  return (
    <div data-testid="home-container" className="home-page-container">
      {cards.map((card) => (
        <Card key={card.key} title={card.title} icon={card.icon} path={card.path} />
      ))}
    </div>
  );
}

export default HomePage;
