import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Container, Box, Typography, TextField } from '@mui/material';
import { setAuth, login } from '../../Contexts/auth/actions';
import useAuthDispatch from '../../hooks/useAuthDispatch';
import getApiUrl from '../../config';
import IuserToken from '../../interfaces/userToken';
import { validateToken } from '../../services/auth';

function Login(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const validateUser = async (userToken: IuserToken) => {
    try {
      await validateToken(userToken);
      setAuth(dispatch, { user: userToken.name, token: userToken.value });
      navigate('/home');
    } catch (error) {
      // console.log('token not valid');
    }
  };

  useEffect(() => {
    const token = searchParams.get('access_token');
    const user = searchParams.get('name');
    if (token && user) {
      const userData: IuserToken = {
        value: token,
        name: user,
      };
      validateUser(userData);
    }
  }, [searchParams]);

  const githubLogin = () => {
    window.location.href = `${getApiUrl()}/oauth/github/login`;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const payload = { email, password };
    try {
      const response = await login(dispatch, payload);
      if (!response.name) return;
      const userData: IuserToken = {
        value: response.token,
        name: response.name,
      };
      validateUser(userData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        backgroundColor: '#f5f5f5',
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          padding: '35px',
        }}
      >
        <Typography component="h1" variant="h5">
          Esntial Dashboard Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            id="email"
            name="email"
            inputProps={{ 'data-testid': 'email' }}
            autoComplete="email"
            margin="normal"
            required
            fullWidth
            autoFocus
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            label="Password"
            id="password"
            name="password"
            inputProps={{ 'data-testid': 'password' }}
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
          />
          <Button
            data-testid="OKTA-button"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            LOGIN WITH OKTA
          </Button>
          <Button
            fullWidth
            style={{ backgroundColor: 'black' }}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={githubLogin}
            data-testid="github-button"
          >
            LOGIN WITH GITHUB
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
