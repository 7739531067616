import React, { Fragment, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Filters from './Filters';
import TimelineCard from '../../components/TimelineCard/TimelineCard';
import { TCardItem, IObjectKeys } from './interfaces';
import useFetchNewRelicAlerts from '../../services/api/useFetchNewRelicAlerts';
import Loader from '../../components/Loader/Loader';

const DEVELOPMENT = 'dev';
const NO_PRODUCTION = 'np';
const PRE_PRODUCTION = 'pp';
const PRODUCTION = 'prod';

const getEnv = (description: string) => {
  if (description.includes('-non')) {
    return NO_PRODUCTION;
  }
  if (description.includes('-pre')) {
    return PRE_PRODUCTION;
  }
  if (description.includes('dev')) {
    return DEVELOPMENT;
  }
  return PRODUCTION;
};

// TODO: order by date
function NewRelicAlerts(): JSX.Element {
  const [filters, setFilters] = useState([DEVELOPMENT, NO_PRODUCTION, PRE_PRODUCTION, PRODUCTION]);
  const { data, isLoading } = useFetchNewRelicAlerts();

  const getKeys = (response: IObjectKeys) => {
    const obj: IObjectKeys = { ...response };
    return Object.keys(obj);
  };

  const getValue = (key: string) => data?.data[key];

  const handleFilters = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setFilters(
      filters.includes(value)
        ? [...filters.filter((filter) => filter !== value)]
        : [...filters, value]
    );
  };

  const applyFilters = (alerts: Array<object>) =>
    alerts.filter((filter: TCardItem) => filters.includes(getEnv(filter.description || '')));

  const renderValues = (alerts: Array<object>) =>
    applyFilters(alerts).map(({ id, description, timestamp }: TCardItem) => (
      <TimelineCard key={id} description={description} timestamp={timestamp} />
    ));

  return (
    <div data-testid="NewRelicAlerts" className="NewRelicAlerts">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Filters data-testid="Filters" filters={filters} handleChange={handleFilters} />
          <Container data-testid="Container">
            <Timeline>
              {getKeys(data?.data).map((key) => (
                <Fragment key={key}>
                  <Chip color="error" label={key} />
                  {renderValues(getValue(key))}
                </Fragment>
              ))}
            </Timeline>
          </Container>
        </>
      )}
    </div>
  );
}

export default NewRelicAlerts;
