const paths = {
  home: '/home',
  healthCheck: '/health-check',
  newRelicAlerts: '/new-relic-alerts',
  sonarQuality: '/sonar-quality',
  appVersions: '/app-versions',
  deployedVersions: '/deployed-versions',
  featureFlags: '/feature-flags',
  experiments: '/experiments',
  changesHistory: '/changes-history',
  recentCommits: '/recent-commits',
  releaseState: '/release-state',
  releaseScope: '/release-scope',
};

export default paths;
