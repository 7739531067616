import React from 'react';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import './Card.scss';

interface CardProps {
  title: string;
  icon: string;
  path: string;
}

function Card({ title, icon, path }: CardProps): JSX.Element {
  return (
    <Link to={path} className="card-link">
      <div className="card-container">
        <Icon path={icon} size={4} className="card-icon" />
        <h2 className="card-title">{title}</h2>
        <p className="card-data">Data</p>
      </div>
    </Link>
  );
}

export default Card;
