import initialState from './initialState';
import Status from '../common/status';
import Types from './types';

const action = {
  type: '',
  errorMessage: '',
  payload: {
    user: '',
    token: '',
  },
};

export default (state = initialState, { type, payload, errorMessage } = action) => {
  switch (type) {
    case Types.Request:
      return {
        ...state,
        status: Status.Loading,
      };
    case Types.Success:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        status: Status.Success,
      };
    case Types.Failure:
      return {
        ...state,
        status: Status.Failure,
        errorMessage,
      };
    case Types.Reset:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
