import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuthState from '../hooks/useAuthState';

function RequireAuth() {
  const { user, status } = useAuthState();
  const location = useLocation();

  return user && status === 'success' ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default RequireAuth;
