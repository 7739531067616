import axios, { AxiosError } from 'axios';
import getApiUrl from '../../config';
import Types from './types';

const API_URL = getApiUrl();

interface IBody {
  email: string;
  password: string;
}
interface IAuth {
  user: string;
  token: string;
}
interface Payload {
  [arg: string]: string | IAuth;
}

type Dispatch = (arg: Payload) => void;

export async function setAuth(dispatch: Dispatch, body: IAuth) {
  const { user, token } = body;
  dispatch({ type: Types.Success, payload: { user, token } });
  localStorage.setItem('user', user);
  localStorage.setItem('token', token);
}

export async function login(dispatch: Dispatch, body: IBody) {
  try {
    dispatch({ type: Types.Request });
    const response = await axios({
      url: `${API_URL}/oauth/okta/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: body,
    });
    const { data } = response || {};
    const { token, name: user } = data || {};

    if (data && token) {
      dispatch({ type: Types.Success, payload: { user, token } });
      localStorage.setItem('user', user);
      localStorage.setItem('token', token);
      return data;
    }

    dispatch({ type: Types.Failure, error: '' });
    return 'error';
  } catch (error) {
    const err = error as Error | AxiosError;
    dispatch({ type: Types.Failure, errorMessage: err.message });
    return 'error';
  }
}

export async function logout(dispatch: Dispatch) {
  dispatch({ type: Types.Reset });
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}
