import { useContext } from 'react';
import { AuthStateContext } from '../Contexts/auth';

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}

export default useAuthState;
