import icons from './icons';
import paths from './paths';

const cards = [
  {
    key: 1,
    title: 'Prod Health Checks',
    path: paths.healthCheck,
    icon: icons.heart,
  },
  {
    key: 2,
    title: 'Prod Health Checks',
    path: paths.healthCheck,
    icon: icons.heartBroken,
  },
  {
    key: 3,
    title: 'NewRelic Prod Alerts',
    path: paths.newRelicAlerts,
    icon: icons.exclamationTriangle,
  },
  {
    key: 4,
    title: 'Overall Code Coverage',
    path: paths.sonarQuality,
    icon: icons.thermometerThreeQuarters,
  },
  {
    key: 5,
    title: 'Active Applications',
    path: paths.appVersions,
    icon: icons.folderOpen,
  },
  {
    key: 6,
    title: 'Deployed Applications',
    path: paths.deployedVersions,
    icon: icons.mobileAlt,
  },
  {
    key: 7,
    title: 'Prod Feature Flags',
    path: paths.featureFlags,
    icon: icons.flag,
  },
  {
    key: 8,
    title: 'Active Experiments',
    path: paths.experiments,
    icon: icons.flask,
  },
  {
    key: 9,
    title: 'Release Scope',
    path: paths.releaseScope,
    icon: icons.user,
  },
  {
    key: 10,
    title: 'Pre-Prod Features',
    path: paths.releaseState,
    icon: icons.boxes,
  },
  {
    key: 11,
    title: 'Released Features',
    path: paths.releaseState,
    icon: icons.checkCircle,
  },
];

export default cards;
