import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import HideOnScroll from '../HideOnScroll/HideOnScroll';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import logoImg from '../../assets/images/logo.png';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface SearchAppBarProps {
  open?: boolean;
  handleDrawerOpen: React.MouseEventHandler<HTMLButtonElement>;
}

function SearchAppBar({ open, handleDrawerOpen }: SearchAppBarProps) {
  return (
    <HideOnScroll>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <Icon path={mdiMenu} size={1} />
          </IconButton>
          <img src={logoImg} alt="" width={30} style={{ marginRight: 10 }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            DevDash
          </Typography>
          <Breadcrumbs />
          <Button color="inherit" sx={{ ml: 2 }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

SearchAppBar.defaultProps = {
  open: true,
};

export default SearchAppBar;
